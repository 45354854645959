import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Player } from "video-react";
import "./index.scss";
import API from "../../API";
import video from "../../assets/video/main_video.mp4";
import preview from "../../assets/img/videopreview.png";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      email: "",
      fullName: "",
      website: "",
      phone: "",
      nameOfBusiness: "",
      numbersOfPeople: "",
      emailOffers: false,
      message: ""
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.get("form")) {
      this.onOpenModal();
    }
  }

  onOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  sendForm = async () => {
    try {
      await API().webForm().save(this.state);
      this.onCloseModal();
    } catch (error) {
      API().handleError(error);
    }
  };

  onSubmitForm = e => {
    e.preventDefault();
    this.sendForm();
  };

  render() {
    const { email, fullName, website, phone, numbersOfPeople, emailOffers, message } = this.state;

    return (
      <div className="container-home">
        <div className="body-wrap boxed-container">
          <header className="site-header">
            <div className="container">
              <div className="site-header-inner">
                <div className="brand header-brand">
                  <h1 className="m-0">
                    <a href="#">
                      <img width="64" height="64" href="home" src={require("../../assets/img/logoIcon.png")} />
                      <title>Slyst | Home</title>
                    </a>
                  </h1>
                </div>
                <ul className="header-links list-reset m-0">
                  <li>
                    <a href="#">Login</a>
                  </li>
                  <li>
                    <a className="button button-sm button-shadow" href="#">
                      Signup
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
          <main>
            <section className="hero text-light text-center">
              <div className="container-sm">
                <div className="hero-inner">
                  <h1
                    id="homeTitle"
                    className="hero-title h2-mobile mt-0 is-revealing"
                    style={{ visibility: "visible", color: "#212529" }}
                  >
                    The Knowledge of the Group in Your Hands
                  </h1>
                  <p className="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                    Increase team productivity, by improving the communication and coordination of the group to achieve specific
                    goals
                  </p>

                  <div className="player-wrapper">
                    <Player playsInline poster="/assets/poster.png" src={video} width="100%" height="100%" poster={preview} />
                  </div>

                  {/* <video style={{ maxWidth: 800, minWidth: 400, width: "100%", height: "100%" }} controls>
                    <source src={require("../../assets/video/main_video.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                </div>
              </div>
            </section>
            <section className="features section text-center">
              <div className="container">
                <div className="features-inner section-inner has-top-divider">
                  <div className="features-wrap">
                    <div className="feature is-revealing" style={{ visibility: "visible" }}>
                      <div className="feature-inner">
                        <a href="founder" id="aboutLink">
                          <div className="feature-icon">
                            <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fillRule="evenodd">
                                <path fill="#84E482" d="M48 16v32H16z"></path>
                                <path fill="#0EB3CE" d="M0 0h32v32H0z"></path>
                              </g>
                            </svg>
                          </div>
                          <h4 className="feature-title h3-mobile">About Slyst:</h4>
                          <p className="text-sm" style={{ color: "black" }}>
                            Learn about the Founder’ story and how Slyst was born
                          </p>
                        </a>
                      </div>
                    </div>
                    <div className="feature is-revealing" style={{ visibility: "visible" }}>
                      <div className="feature-inner">
                        <a href="features" id="aboutLink">
                          <div className="feature-icon">
                            <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fillRule="evenodd">
                                <path fill="#84E482" d="M48 16v32H16z"></path>
                                <path fill="#0EB3CE" d="M0 0v32h32z"></path>
                                <circle fill="#02C6A4" cx="29" cy="9" r="4"></circle>
                              </g>
                            </svg>
                          </div>
                          <h4 className="feature-title h3-mobile">Product features:</h4>
                          <p className="text-sm" style={{ color: "black" }}>
                            See the different ways you can use Slyst in your work, with your friends or by yourself. Check the
                            different features and more.
                          </p>
                        </a>
                      </div>
                    </div>
                    <div
                      className="feature is-revealing"
                      style={{ visibility: "visible", cursor: "pointer" }}
                      onClick={this.onOpenModal}
                    >
                      <div className="feature-inner">
                        <a data-toggle="modal" data-target="#exampleModalCenter" id="modalLi">
                          <div className="feature-icon">
                            <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fillRule="evenodd">
                                <path fill="#0EB3CE" d="M0 0h32v32H0z"></path>
                                <path fill="#84E482" d="M16 16h32L16 48z"></path>
                              </g>
                            </svg>
                          </div>
                          <h4 className="feature-title h3-mobile">Contact us:</h4>
                          <p className="text-sm" style={{ color: "black" }}>
                            Send us your feedback, receive offers and more.
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <footer className="site-footer">
          <div className="container">
            <div className="site-footer-inner">
              <div className="brand footer-brand">
                <a href="#">
                  <img width="64" height="64" src={require("../../assets/img/logoLetters.png")} />
                  <title>Slyst</title>
                </a>
              </div>
              <div className="footer-copyright">© 2020 Slyst, all rights reserved</div>
            </div>
          </div>
        </footer>
        <Modal
          show={this.state.isModalOpen}
          onHide={this.onCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Let us get to know more about you and your team.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.onSubmitForm}>
              <Form.Group>
                <Form.Label>Full Name *</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  required
                  value={fullName}
                  onChange={e => this.setState({ fullName: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  type="email"
                  size="sm"
                  required
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="(Optional)"
                  size="sm"
                  value={phone}
                  onChange={e => this.setState({ phone: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Number of people in your team?</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="(Optional)"
                  size="sm"
                  value={numbersOfPeople}
                  onChange={e => this.setState({ numbersOfPeople: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Website</Form.Label>
                <Form.Control type="text" size="sm" value={website} onChange={e => this.setState({ website: e.target.value })} />
              </Form.Group>
              <Form.Group controlId="check">
                <Form.Check
                  type="checkbox"
                  label=" Are you interested in receiving by email updates, offers, discounts, etc?"
                  size="sm"
                  checked={emailOffers}
                  onChange={e => this.setState({ emailOffers: e.target.checked })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  as="textarea"
                  rows="4"
                  value={message}
                  onChange={e => this.setState({ message: e.target.value })}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
