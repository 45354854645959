import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import JWT from "jsonwebtoken";
import Loading from "../../components/loading";
import API from "../../API";
import "./index.scss";

export default class Invitation extends Component {
  constructor(props) {
    super(props);

    this.token = "";
    this.id = "";

    this.state = {
      isLoading: true,
      isError: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      isArchived: false
    };
  }

  componentDidMount() {
    try {
      const params = new URLSearchParams(this.props.history.location.search);
      JWT.verify(params.get("token"), process.env.REACT_APP_JWT_SECRET);
      this.token = params.get("token");
      this.id = this.props.match.params.id;
      this.getData();
    } catch (error) {
      this.setState({ isError: true, isLoading: false });
    }
  }

  async getData() {
    try {
      const invitations = await API(this.token).invitation(this.id).getById();
      const email = invitations.data.email;
      const isArchived = invitations.data.isArchived;
      this.setState({ email, isLoading: false, isArchived });
    } catch (error) {
      this.setState({ isError: true, isLoading: false });
    }
  }

  onInvitationAccept = e => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    this.acceptInvitation();
  };

  acceptInvitation = async () => {
    try {
      this.setState({ isLoading: true });
      const { firstName, lastName, password } = this.state;
      await API(this.token).invitation(this.id).accept(firstName, lastName, password);
      this.setState({ isLoading: false, isArchived: true });
    } catch (error) {
      this.setState({ isLoading: false });
      API().handleError(error);
    }
  };

  render() {
    const { isLoading, isError, email, firstName, lastName, password, confirmPassword, isArchived } = this.state;
    if (isLoading) {
      return (
        <div className="container-founder">
          <div className="body-wrap boxed-container">
            <main>
              <Loading />
            </main>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-founder">
          <div className="body-wrap boxed-container">
            <header className="site-header">
              <div className="container">
                <div className="site-header-inner">
                  <div className="brand header-brand">
                    <h1 className="m-0">
                      <a href="https://slyst.com">
                        <img width="64" height="64" href="home" src={require("../../assets/img/logoNew.png")} />
                        <title>Slyst | Home</title>
                      </a>
                    </h1>
                  </div>
                  <ul className="header-links list-reset m-0">
                    <li>
                      <a href="#">Login</a>
                    </li>
                    <li>
                      <a className="button button-sm button-shadow" href="#">
                        Signup
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
            <main>
              {isArchived && (
                <section class="hero text-light text-center">
                  <div class="container-sm">
                    <div class="hero-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <img src="/img/check.png" class="img-responsive center-block" width="80px" />
                        </div>
                      </div>

                      <h3 class="hero-title h2-mobile mt-0 is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                        Invitation accepted
                      </h3>
                      <p className="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                        Get access from your phone using the App and start collaborating on the channel
                      </p>
                    </div>
                  </div>
                </section>
              )}
              {isError && (
                <section class="hero text-light text-center">
                  <div class="container-sm">
                    <div class="alert alert-danger" role="alert">
                      An error has occurred, your invitation has been deleted or your token has expired. Please request a new
                      invitation
                    </div>
                  </div>
                </section>
              )}

              {!isError && !isArchived && (
                <div class="container-sm" style={{ marginBottom: "20px" }}>
                  <Form onSubmit={this.onInvitationAccept}>
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={firstName}
                        onChange={e => this.setState({ firstName: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={lastName}
                        onChange={e => this.setState({ lastName: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="email">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" defaultValue={email} disabled />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        required
                        value={password}
                        onChange={e => this.setState({ password: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        required
                        value={confirmPassword}
                        onChange={e => this.setState({ confirmPassword: e.target.value })}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </div>
              )}

              <footer className="site-footer">
                <div className="container">
                  <div className="site-footer-inner">
                    <div className="brand footer-brand">
                      <a href="https://slyst.com">
                        <img width="64" height="64" src={require("../../assets/img/logoLetters.png")} />
                        <title>Slyst</title>
                      </a>
                    </div>
                    <div className="footer-copyright" style={{ textAlign: "right", justifyContent: "center" }}>
                      © 2020 Slyst, all rights reserved
                    </div>
                  </div>
                </div>
              </footer>
            </main>
          </div>
        </div>
      );
    }
  }
}
