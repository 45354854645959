import React from "react";
import "./index.scss";

export default function Founder() {
  //TODO: Try to reduce this code moving  to the mobile repository or if it will be used by the web repository use it there. The API supposed to return only json or send html for emails
  return (
    <div className="container-founder">
      <div className="body-wrap boxed-container">
        <header className="site-header">
          <div className="container">
            <div className="site-header-inner">
              <div className="brand header-brand">
                <h1 className="m-0">
                  <a href="/">
                    <img width="64" height="64" href="home" src={require("../../assets/img/logoIcon.png")} />
                    <title>Slyst | Home</title>
                  </a>
                </h1>
              </div>
              <ul className="header-links list-reset m-0">
                <li>
                  <a href="#">Login</a>
                </li>
                <li>
                  <a className="button button-sm button-shadow" href="#">
                    Signup
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <main>
          <section class="hero text-light text-center">
            <div class="container-sm">
              <div class="hero-inner">
                <h1 class="hero-title h2-mobile mt-0 is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  The Founder’ Story
                </h1>
                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  The founder started in Chile as an electronic engineer and after being hired by the largest internet Hispanic
                  company (Terra Network) in the world at that time, was transferred to the U.S. to help in the development of the
                  new office.
                </p>

                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  {" "}
                  Because the founder always has been a creative person, he always was trying to provide ideas to reduce costs or
                  create new ways to diversify and generate revenue, but like every big company with the wrong culture, the top
                  management were unreachable because they didn’t have interest in the opinion of their workers, therefore the
                  founder received answers like “we cannot do that, because nobody does that” or “you are forbidden to contact top
                  managers”, or “OK, thank you for the idea, good bye”.
                </p>
                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  After more than 17 years working at Terra Networks and having put on many hats with different responsibilities,
                  the company went bankrupt and closed its offices in the U.S.
                </p>

                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  Sadly, the founder was unable to find a job in his field mainly because the founder was not a perfect
                  communicator, therefore after having spent all his savings, he started from below and found a part time job in a
                  retail store as sales associate, earning almost the minimum wage.
                </p>
              </div>
            </div>

            <div class="container-sm">
              <div class="hero-inner">
                <h1
                  class="hero-title h2-mobile mt-0 is-revealing text-dark"
                  style={{ visibility: "visible", color: "#21252 !important" }}
                >
                  Every cloud has a silver lining
                </h1>
                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  Because the founder always wants to provide value, the founder asked to different supervisors and managers “what
                  is the process to communicate issues I detected in the store, like products that need to be restock to avoid to
                  lose a sale, or products that are in the wrong location, therefore have the wrong information and price”, but
                  every supervisor or manager gave him a different answer: “send me a picture using WhatsApp”, “write a note in a
                  paper and give it to me”, “send me a text message”, “try to locate me and tell me”.
                </p>

                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  That was the moment when the founder realized that something was wrong, and something should be done to have a
                  better way to communicate this kind of important information. Something where the associate can transmit
                  information fast and easy, and where the managers can receive the information from all the associates
                  automatically organized, in order to easily prioritize them.
                </p>
              </div>
            </div>

            <div class="container-sm">
              <div class="hero-inner">
                <h1 class="hero-title h2-mobile mt-0 is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  Slyst{" "}
                </h1>
                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  The founder tried to find a mobile app to be used for these purposes, but he didn’t find something appropriate,
                  because it needed to be fast &amp; simple for the people who will communicate the information and the data
                  should be able to be organized easily by the managers, in order to prioritize it.
                </p>
                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  After several weeks trying to find a way to communicate the information, the founder was working was working in
                  his computer for several hours analyzing data, when he had the idea about the main functionalities the mobile
                  app should have in order to easily organize any kind of information shared between many people, and that was the
                  precise moment when Slyst was born.
                </p>

                <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                  If Slyst had existed in Terra Networks’ time and the CEO would have used it, Terra would not have got bankrupt,
                  because Slyst allows you to use the knowledge of your team to achieve diverse goals. For example, the top
                  directives at Terra Networks made several disastrous decisions that the people in charge of implementing them
                  knew in advance they would not work.
                </p>
              </div>
            </div>
          </section>
          <footer className="site-footer">
            <div className="container">
              <div className="site-footer-inner">
                <div className="brand footer-brand">
                  <a href="/">
                    <img width="64" height="64" src={require("../../assets/img/logoLetters.png")} />
                    <title>Slyst</title>
                  </a>
                </div>
                <div className="footer-copyright">© 2020 Slyst, all rights reserved</div>
              </div>
            </div>
          </footer>
        </main>
      </div>
    </div>
  );
}
