import React, { Component, Fragment } from "react";
import JWT from "jsonwebtoken";
import { Alert, Form, Button } from "react-bootstrap";
import MainContainer from "../../components/container";
import Loading from "../../components/loading";
import API from "../../API";
import "./index.scss";

export default class Reset extends Component {
  constructor(props) {
    super(props);

    this.token = "";

    this.state = {
      isLoading: true,
      isError: false,
      isSuccess: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      isArchived: false
    };
  }

  componentDidMount() {
    try {
      const params = new URLSearchParams(this.props.history.location.search);
      JWT.verify(params.get("token"), process.env.REACT_APP_JWT_SECRET);
      this.token = params.get("token");
      this.getData();
    } catch (error) {
      this.setState({ isError: true, isLoading: false });
    }
  }

  async getData() {
    try {
      const user = await API(this.token).user().identify();
      const email = user.data.email;
      const firstName = user.data.firstName;
      const lastName = user.data.lastName;
      this.setState({ email, firstName, lastName, isLoading: false });
    } catch (error) {
      this.setState({ isError: true, isLoading: false });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    this.resetPassword();
  };

  resetPassword = async () => {
    try {
      this.setState({ isLoading: true });
      const { password } = this.state;
      await API(this.token).user().passwordReset(password);
      this.setState({ isSuccess: true, isLoading: false });
    } catch (error) {
      API().handleError(error);
      this.setState({ isError: true, isLoading: false });
    }
  };

  render() {
    const { isLoading, isError, isSuccess, email, firstName, lastName, password, confirmPassword } = this.state;

    return (
      <MainContainer {...this.props} className="container-reset">
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Alert variant="danger">An error has occurred, your token has expired.</Alert>
        ) : (
          <Fragment>
            <h4>
              Hello {firstName} {lastName}
            </h4>
            <br />
            {isSuccess ? (
              <Fragment>
                <Alert variant="success">Your password has been changed.</Alert>
                <a className="text-white text-decoration-none" href="https://app.tabularplan.com">
                  <Button className="w-fit-content mt-3 mb-3" variant="primary">
                    Login
                  </Button>
                </a>
              </Fragment>
            ) : (
              <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" defaultValue={email} disabled />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>New password</Form.Label>
                  <Form.Control
                    type="password"
                    required
                    value={password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                  />
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                  <Form.Label>Confirm new password</Form.Label>
                  <Form.Control
                    type="password"
                    required
                    value={confirmPassword}
                    onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            )}
          </Fragment>
        )}
      </MainContainer>
    );
  }
}
