import React, { Component } from "react";
import MainContainer from "../../components/container";
import "./index.scss";

export default class Login extends Component {
  render() {
    return (
      <MainContainer {...this.props} className="container-login">
        <h2>Login page</h2>
      </MainContainer>
    );
  }
}
