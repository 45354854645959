import React, { Component } from "react";
import Loading from "../../components/loading";
import API from "../../API";
import "./index.scss";

export default class Validate extends Component {
  constructor(props) {
    super(props);

    this.id = "";

    this.state = {
      isLoading: true,
      isError: false,
      firstName: "",
      lastName: ""
    };
  }

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getData();
  }

  async getData() {
    try {
      const user = await API().user(this.id).validate();
      const firstName = user.data.firstName;
      const lastName = user.data.lastName;
      this.setState({ firstName, lastName, isLoading: false });
    } catch (error) {
      this.setState({ isError: true, isLoading: false });
    }
  }

  render() {
    const { isLoading, isError, firstName, lastName } = this.state;
    if (isLoading) {
      return (
        <div className="container-founder">
          <div className="body-wrap boxed-container">
            <main>
              <Loading />
            </main>
          </div>
        </div>
      );
    }

    return (
      <div className="container-founder">
        <div className="body-wrap boxed-container">
          <header className="site-header">
            <div className="container">
              <div className="site-header-inner">
                <div className="brand header-brand">
                  <h1 className="m-0">
                    <a href="https://tabularplan.com">
                      <img width="64" height="64" href="home" src={require("../../assets/img/logoNew.png")} />
                      <title>Tabular Plan | Home</title>
                    </a>
                  </h1>
                </div>
                <ul className="header-links list-reset m-0">
                  <li>
                    <a href="#">Login</a>
                  </li>
                  <li>
                    <a className="button button-sm button-shadow" href="#">
                      Signup
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
          <main>
            {isError ? (
              <section class="hero text-light text-center">
                <div class="container-sm">
                  <div class="alert alert-danger" role="alert">
                    An error has occurred, your account could not be validated.
                  </div>
                </div>
              </section>
            ) : (
              <section class="hero text-light text-center">
                <div class="container-sm">
                  <div class="hero-inner">
                    <h3 class="hero-title h2-mobile mt-0 is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                      Hello {firstName} {lastName}, your email has been verified
                    </h3>
                    <p class="hero-paragraph is-revealing" style={{ visibility: "visible", color: "#212529" }}>
                      Login in your phone and start creating your first Channel of Information, shared with your friends or
                      teammates
                    </p>
                  </div>
                </div>
              </section>
            )}
            <footer className="site-footer">
              <div className="container">
                <div className="site-footer-inner">
                  <div className="brand footer-brand">
                    <a href="https://tabularplan.com">Tabular Plan</a>
                  </div>
                  <div className="footer-copyright" style={{ textAlign: "right", justifyContent: "center" }}>
                    © 2023 Tabular Plan, all rights reserved
                  </div>
                </div>
              </div>
            </footer>
          </main>
        </div>
      </div>
    );
  }
}
