import Home from "../views/home";
import Founder from "../views/founder";
import Features from "../views/features";
import Login from "../views/login";
import Register from "../views/register";
import Invitation from "../views/invitation";
import Validate from "../views/validate";
import Reset from "../views/reset";
import NotFound from "../views/notfound";

export default {
  home: {
    component: Home,
    inFooter: false,
    inHeader: true,
    meta: {
      description: "Home page",
      keywords: ["Home", "Page", "Landing"]
    },
    name: "Home",
    path: "/",
    public: true
  },
  founder: {
    component: Founder,
    inFooter: false,
    inHeader: true,
    meta: {
      description: "Founder page",
      keywords: ["Founder", "Page"]
    },
    name: "Founder",
    path: "/founder",
    public: true
  },
  features: {
    component: Features,
    inFooter: false,
    inHeader: true,
    meta: {
      description: "Features page",
      keywords: ["Features", "Page"]
    },
    name: "Features",
    path: "/features",
    public: true
  },
  login: {
    component: Login,
    inFooter: false,
    inHeader: false,
    meta: {
      description: "Login page",
      keywords: ["Login", "Page", "Slyst"]
    },
    name: "Login",
    path: "/login",
    public: true
  },
  register: {
    component: Register,
    inFooter: false,
    inHeader: false,
    meta: {
      description: "Register page",
      keywords: ["Register", "Page", "Slyst"]
    },
    name: "Register",
    path: "/register",
    public: true
  },
  invitation: {
    component: Invitation,
    inFooter: false,
    inHeader: false,
    meta: {
      description: "Invitacion page",
      keywords: ["Invitation", "Page", "Slyst"]
    },
    name: "Invitation",
    path: "/invitation/:id",
    public: true
  },
  validate: {
    component: Validate,
    inFooter: false,
    inHeader: false,
    meta: {
      description: "Validate page",
      keywords: ["Validate", "Page", "Slyst"]
    },
    name: "Validate",
    path: "/validate/:id",
    public: true
  },
  reset: {
    component: Reset,
    inFooter: false,
    inHeader: false,
    meta: {
      description: "Reset password page",
      keywords: ["Reset", "Page", "Slyst"]
    },
    name: "Reset password",
    path: "/reset",
    public: true
  },
  notfound: {
    component: NotFound,
    inFooter: false,
    inHeader: false,
    meta: {
      description: "Page not found",
      keywords: ["Not", "Found"]
    },
    name: "Not Found",
    path: "",
    public: true
  }
};
