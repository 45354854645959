import axios from "axios";

//TODO:  add a baseURL as env config
let baseURL = `${window.location.origin}/api`;
if (window.location.hostname === "localhost") baseURL = "http://localhost:5001/api";
axios.defaults.baseURL = baseURL;

const GET = "GET";
const POST = "POST";
const PATCH = "PATCH";

//TODO: log backend interaction
export default (token = "") => {
  axios.defaults.headers["X-USER-TOKEN"] = token || localStorage.getItem("X-USER-TOKEN") || "";
  return {
    invitation: id => {
      let url = `${baseURL}/invitation${id ? `/${id}` : ""}`;
      return {
        getById: async () => {
          const r = await axios({
            url,
            method: GET
          }).catch(error => {
            console.log("api invitation error ", error);
          });
          return r.data;
        },
        accept: async (firstName, lastName, password) => {
          url += "/accept";
          const r = await axios({
            url,
            method: POST,
            data: { firstName, lastName, password }
          }).catch(error => {
            console.log("api accept error ", error);
          });
          return r.data;
        }
      };
    },
    user: id => {
      let url = `${baseURL}/user${id ? `/${id}` : ""}`;
      return {
        validate: async () => {
          url += "/validate";
          const r = await axios({
            url,
            method: GET
          }).catch(error => {
            console.log("api validate error ", error);
          });
          return r.data;
        },
        identify: async () => {
          url += "/identify";
          const r = await axios({
            url,
            method: GET
          }).catch(error => {
            console.log("api identify error ", error);
          });
          return r.data;
        },
        passwordReset: async password => {
          url += "/password/reset";
          const r = await axios({
            url,
            method: PATCH,
            data: { password }
          }).catch(error => {
            console.log("api password reset error ", error);
          });
          return r.data;
        }
      };
    },
    webForm: () => {
      let url = "/webForm";

      return {
        save: async data => {
          const r = await axios({
            url,
            method: POST,
            data
          }).catch(error => {
            console.log("api webform error ", error);
          });
          return r.data;
        }
      };
    },
    getParams: data =>
      Object.keys(data)
        .map(k => `${k}=${data[k]}`)
        .join("&"),
    handleError: (error = new Error("")) => {
      console.log(error.response);
      alert(error.response?.data.message || error.message);
    },
    baseURL
  };
};
