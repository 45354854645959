import React from "react";
import "./index.scss";

export default function Features() {
  return (
    <div className="container-founder">
      <div className="body-wrap boxed-container">
        <header class="site-header">
          <div id="add-react-example"></div>
          <div class="container">
            <div class="site-header-inner">
              <div class="brand header-brand">
                <h1 class="m-0">
                  <a href="/">
                    <img width="64" height="64" viewbox="0 0 32 32" href="home" src={require("../../assets/img/logoIcon.png")} />
                    <title>Slyst | Home</title>
                    <defs>
                      <radialgradient cy="0%" fx="50%" fy="0%" r="100%" id="logo-gradient">
                        <stop stop-color="#FFF" offset="0%">
                          <stop stop-color="#FFF" stop-opacity=".24" offset="100%"></stop>
                        </stop>
                      </radialgradient>
                    </defs>
                    <path
                      d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-10a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                      fill="url(#logo-gradient)"
                      fill-rule="evenodd"
                    ></path>
                  </a>
                </h1>
              </div>
              <ul class="header-links list-reset m-0">
                <li style={{ color: "#212529" }}>
                  <a href="#">Login</a>
                </li>
                <li style={{ color: "#212529" }}>
                  <a class="button button-sm button-shadow" href="#">
                    Signup
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <main>
          <section class="hero text-light text-justify ">
            <div class="container-sm">
              <div class="hero-inner">
                <h1 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Increase Revenue Improving Product Availability - For Store’s Managers
                </h1>
                <p style={{ color: "#212529" }}>
                  How to use Slyst, in order to improve “Product Availability” at stores and create efficient ways to organize the
                  communication between Managers, Supervisors and Sales Representatives, in order to avoid missing a sale because
                  of lack of products at the store’s shelves.
                </p>
                <h1 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Objectives:
                </h1>

                <ol>
                  <li style={{ color: "#212529" }}>
                    Increase revenue, avoiding losing a sale because of empty shelves, wrong prices, or any other issues.
                  </li>
                  <li style={{ color: "#212529" }}>
                    To create a fast and easy-to follow procedure for the employees, to inform to managers about products that
                    need to be purchased, restock or any other actions.
                  </li>
                  <li style={{ color: "#212529" }}>
                    To have only one easy-to-generate table, with the information communicated by all the employees about all the
                    products where actions need to be taken.
                  </li>
                </ol>

                <h1 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Slyst’s configuration:
                </h1>

                <ol>
                  <li style={{ color: "#212529" }}>
                    You can create a Channel of Information called “Product Availability” under a Workspace with the name of your
                    Company, with the following fields to be filled by any Sales Representative or Supervisor who detect any issue
                    with a product.
                    <dl>
                      <dd style={{ color: "#212529" }}>a - Name of the product (mandatory)</dd>

                      <dd style={{ color: "#212529" }}>b - Location in the store</dd>

                      <dd style={{ color: "#212529" }}>c - Serial Number or Bar Code</dd>

                      <dd style={{ color: "#212529" }}>
                        d - Problem: (Drop down menu with the following options: No Product Available, Few units available, Wrong
                        Price, Other)
                      </dd>

                      <dd style={{ color: "#212529" }}>e - Comment</dd>
                    </dl>
                  </li>
                  <li style={{ color: "#212529" }}>
                    The Sales Representatives can be configured as “Senders”, because they don’t need to have access to the
                    information communicated by other workers.
                    <p style={{ color: "#212529" }}>
                      The Supervisors can be configured as “Senders &amp; Receivers”, so in that way they have access to the
                      Summary with all the information sent by the Sales Representatives and are able to put comments to the
                      information sent by them.
                    </p>
                  </li>
                </ol>

                <h1 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Conclusion:
                </h1>

                <p style={{ color: "#212529" }}>
                  You will have in only one place, all this information communicated to you by all the employees, where you can
                  easily see it in your phone and edit them, create alerts, delete or export all the information to excel, in
                  order to take corrective actions
                </p>

                <p style={{ color: "#212529" }}>
                  Also, you will be able to easily delete or archive past information that you don’t need anymore.{" "}
                </p>
              </div>
            </div>
          </section>

          <section class="hero text-light text-justify is">
            <div class="container-sm">
              <div class="hero-inner">
                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Shared Schedule with College’s Assignments - For College Students
                </h2>
                <p style={{ color: "#212529" }}>
                  How to use Slyst, in order to avoid misunderstanding with College HomeWorks, Tests or Projects, creating a
                  unified schedule where all the classmates can share information and put comments about future assignments.
                </p>
                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Objectives:
                </h2>

                <ol>
                  <li style={{ color: "#212529" }}>
                    To make sure the students are going to be well prepared to any homework, test or project in the future.
                  </li>
                  <li style={{ color: "#212529" }}>
                    To create a fast and easy way to communicate information about future assignments, between all classmates
                  </li>
                  <li style={{ color: "#212529" }}>
                    To have only one easy-to-generate schedule, with the information communicated by all the classmates, about all
                    future assignments.
                  </li>
                </ol>

                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Slyst’s configuration:
                </h2>

                <ol>
                  <li style={{ color: "#212529" }}>
                    You can create a Channel of Information called “Class Schedule” under a Workspace with the name of your
                    College, with the following fields to be filled by the any classmates who have any new information regarding
                    future assignments
                    <dl>
                      <dd style={{ color: "#212529" }}>a - Due date of the Assignment (mandatory)</dd>

                      <dd style={{ color: "#212529" }}>
                        b - Subject (Drop down menu with, for example, the following options: Math, English, History, Science,
                        etc.)
                      </dd>

                      <dd style={{ color: "#212529" }}>
                        c - Type of the Assignment (Drop down menu with, for example, the following options: Test, Quiz, Exam,
                        Project, Homework, etc.)
                      </dd>

                      <dd style={{ color: "#212529" }}>d - comment</dd>
                    </dl>
                  </li>
                  <li style={{ color: "#212529" }}>
                    All the Classmates can be configured as “Senders &amp; Receivers”, so in that way all of them will have access
                    to the schedule with all the information sent by everybody, and all are going to be able to put comments to
                    any information received.
                  </li>
                </ol>

                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Conclusion:
                </h2>

                <p style={{ color: "#212529" }}>
                  All the Classmates are going to have only one shared Schedule, containing the information communicated by all of
                  them about future assignments, avoiding in that way possible misunderstandings, where everybody will have easy
                  access the information through their phones, put comments, create alerts, and much more.
                </p>

                <p style={{ color: "#212529" }}>
                  Also, you will be able to easily delete or archive past information that you don’t need anymore.{" "}
                </p>
              </div>
            </div>
          </section>

          <section class="hero text-light text-justify">
            <div class="container-sm">
              <div class="hero-inner">
                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Management of Employees/Contractors’ Worked Hours - For Managers
                </h2>
                <p style={{ color: "#212529" }}>
                  How to use Slyst in order to keep a good control of the weekly hours worked by Contractors, avoiding any
                  surprise of unexpected charges at the end of the week.
                </p>
                <h1 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Objectives:
                </h1>

                <ol>
                  <li style={{ color: "#212529" }}>
                    To keep a good control of the budget used to pay hourly-Contractors, saving time and avoiding unexpected
                    charges at the end of the week.
                  </li>
                  <li style={{ color: "#212529" }}>
                    To create a fast and easy-to follow procedure for the Contractors, to inform Managers about jobs done and
                    hours worked
                  </li>
                  <li style={{ color: "#212529" }}>
                    To have one easy-to-generate unique table, with the complete information communicated by all the Contractors
                    about the jobs done, duration, time, etc.
                  </li>
                </ol>

                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Slyst’s configuration:
                </h2>
                <ol>
                  <li style={{ color: "#212529" }}>
                    You can create a Channel of Information called “Work Done by Contractors” under a Workspace with the name of
                    your Company, with the following fields to be filled by any Contractor, immediately after he/she finish any
                    job.
                    <dl>
                      <dd style={{ color: "#212529" }}>a - Work Start Date and Time (mandatory)</dd>
                      <dd style={{ color: "#212529" }}>b - Work End Date and Time (mandatory)</dd>
                      <dd style={{ color: "#212529" }}>c - Description of the work done (mandatory)</dd>

                      <dd style={{ color: "#212529" }}>
                        d - Status (Drop down menu with, for example, the following options: Resolved, Pending, Other) (mandatory)
                      </dd>

                      <dd style={{ color: "#212529" }}>e - Comment</dd>
                    </dl>
                  </li>
                  <li style={{ color: "#212529" }}>
                    The Contractors can be configured as “Senders”, because they don’t need to have access to the information
                    communicated by other Contractors.
                    <p style={{ color: "#212529" }}>
                      The Supervisors can be configured as “Senders &amp; Receivers”, so in that way they have access to the
                      Summary with all the information sent by the Contractors and are able to put comments to the information
                      sent by them.
                    </p>
                  </li>
                </ol>

                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Conclusion:
                </h2>

                <p style={{ color: "#212529" }}>
                  You will have in only one place, all this information communicated to you by all the Contractors, where you can
                  easily see it in your phone and edit them, create alerts, delete some of them, or export all the information to
                  excel.
                </p>

                <p style={{ color: "#212529" }}>
                  Also, you will be able to easily delete or archive past information that you don’t need anymore.{" "}
                </p>
              </div>
            </div>
          </section>

          <section class="hero text-light text-justify">
            <div class="container-sm">
              <div class="hero-inner">
                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Organize Tasks and Create Reminding Alerts - For Everybody
                </h2>
                <p style={{ color: "#212529" }}>
                  How to use Slyst in order to organize and keep control of the important tasks you need to do in the future, in
                  an easy, fast and straightforward way, and creating reminding alarms for the most important ones.
                </p>
                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Objectives:
                </h2>

                <ol>
                  <li style={{ color: "#212529" }}>
                    To avoid missing important things to do in the future, like meetings, watch TV programs, go to places, or any
                    task you consider is important not to forget.
                  </li>
                  <li style={{ color: "#212529" }}>To have a fast way to put the information and create the alarms.</li>
                  <li style={{ color: "#212529" }}>
                    To receive the alarm in the date a time required, accessing the information easily and straightforward.
                  </li>
                </ol>

                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Slyst’s configuration:
                </h2>

                <ol>
                  <li style={{ color: "#212529" }}>
                    You can create a Channel of Information called “Tasks to do” under a Workspace with the name” Personal”, with
                    the following fields to be filled by you, as soon as you know about something you don’t have to forget
                    <dl>
                      <dd style={{ color: "#212529" }}>a - Task to do</dd>

                      <dd style={{ color: "#212529" }}>b - When?</dd>

                      <dd style={{ color: "#212529" }}>c - Where?</dd>

                      <dd style={{ color: "#212529" }}>d - Date and Time</dd>

                      <dd style={{ color: "#212529" }}>e - Comments</dd>
                    </dl>
                  </li>
                  <li style={{ color: "#212529" }}>
                    You can also configure to receive an alarm regarding any information filled, at a future date and time defined
                    by you.
                  </li>
                </ol>

                <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                  Conclusion:
                </h2>

                <p style={{ color: "#212529" }}>
                  You will have in only one place, all the important tasks you need to do in the future, and in addition you are
                  going to be able to set alarms in future date and time, to give you a reminder about any important task you need
                  to do.
                </p>

                <p style={{ color: "#212529" }}>
                  Also, you will be able to easily delete or archive past information that you don’t need anymore.{" "}
                </p>

                <p style={{ color: "#212529" }}>
                  If you want your family’s members to participate in your “Tasks to do”, you can invite them a give them
                  privileges as “Senders &amp; Receivers” so in that way they have access to the Summary with all the information
                  sent by you, and are able to put comments.
                </p>

                <section class="hero text-light text-justify">
                  <div class="container-sm">
                    <div class="hero-inner">
                      <h2 class="hero-title h2-mobile mt-0" style={{ visibility: "visible", color: "#212529" }}>
                        FAQ:
                      </h2>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        What is Slyst and what can I use it for?:
                      </h3>

                      <p style={{ color: "#212529" }}>
                        Slyst is a productivity tool, to help you manage important information communicated recurrently between
                        you, your team or your family, organized in only one place in an easy, fast and straightforward way.
                      </p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        For example for the family:
                      </h3>

                      <p style={{ color: "#212529" }}>
                        Slyst allow them to have a unique shared data base of recurrent information like things to do on weekends,
                        merchandise to buy in the supermarket, or any kind of frequent information to share, in order to have a
                        better coordination between them.
                      </p>
                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        For College students:
                      </h3>

                      <p style={{ color: "#212529" }}>
                        Slyst allows them to have a unique Schedule, where they can share and put periodic information or comments
                        about their future assignments like HomeWorks, Projects, Test, etc.
                      </p>
                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        For the business:
                      </h3>

                      <p style={{ color: "#212529" }}>
                        Slyst allow them to organize efficiently the information received frequently by them from hourly-hired
                        Contractors, regarding the amount of worked hours, activity executed, dates, etc., avoiding any surprise
                        of unexpected charges at the end of the week.
                      </p>
                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        For a Store Managers:
                      </h3>

                      <p style={{ color: "#212529" }}>
                        Slyst allows them to increase revenue improving the “Product Availability” at stores, creating efficient
                        ways to organize the constant communication between Managers, Supervisors and Sales Representatives,
                        regarding lack of products at the store’s shelves.
                      </p>
                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        For everybody:
                      </h3>

                      <p style={{ color: "#212529" }}>
                        Slyst allows you to organize and keep control of the important tasks you need to do in the future, in an
                        easy, fast and straightforward way, where you can also create reminding alarms for the most important
                        ones.
                      </p>
                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        And many other ways where you can use Slyst
                      </h3>
                    </div>
                  </div>
                </section>

                <section class="hero text-light text-justify">
                  <div class="container-sm">
                    <div class="hero-inner">
                      <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        PREMIUM FEATURES:
                      </h2>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        Users can send private messages to other users.
                      </h3>
                      <p style={{ color: "#212529" }}>
                        You can send messages to all the group in the specific Channel of Information in the free version of the
                        app.
                      </p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        The user can schedule when will be sent the information filled in any Channel of Information.
                      </h3>
                      <p style={{ color: "#212529" }}>The information is sent immediately in the free version of the app.</p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        The Administrator can configure unlimited “Channels of Information” for each Workspace.
                      </h3>
                      <p style={{ color: "#212529" }}>
                        The Administrator can configure only two “Channels of Information” for each Workspace in the free version
                        of the app.
                      </p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        The information can be watched in a calendarized format, according with the field “Date&amp;Time”.
                      </h3>
                      <p style={{ color: "#212529" }}>This option is not available in the free version of the app.</p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        The administrators can create drop down menu fields.
                      </h3>
                      <p style={{ color: "#212529" }}>This option is not available in the free version of the app.</p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        You can schedule Alarms, regarding packets of information received or sent.
                      </h3>
                      <p style={{ color: "#212529" }}>This option is not available in the free version of the app.</p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        You can add functionalities of Bar-Code-Scanner to any Channel of Information
                      </h3>
                      <p style={{ color: "#212529" }}>This option is not available in the free version of the app.</p>
                    </div>
                  </div>
                </section>

                <section class="hero text-light text-justify">
                  <div class="container-sm">
                    <div class="hero-inner">
                      <h2 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        GOLDEN PREMIUM FEATURES
                      </h2>
                      <p style={{ color: "#212529" }}>All Premium features plus:</p>
                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        The Channels of Information can have unlimited fields.
                      </h3>
                      <p style={{ color: "#212529" }}>
                        The Channels of Information can have maximum 5 fields in the free version of the app.
                      </p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        The Administrator can give administration powers to unlimited people.
                      </h3>
                      <p style={{ color: "#212529" }}>
                        The maximum number of Administrators are two in the free version of the app.
                      </p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        Each Administrator can create unlimited Workspaces.
                      </h3>
                      <p style={{ color: "#212529" }}>You can create only 2 Workspaces in the free version of the app.</p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        Senders can send up to 5 pictures in each packet of information.
                      </h3>
                      <p style={{ color: "#212529" }}>
                        Senders can send only 1 picture in each packet of information in the free version of the app.
                      </p>

                      <h3 class="hero-title h2-mobile mt-0 " style={{ visibility: "visible", color: "#212529" }}>
                        Users can send unlimited packets of information in any Channel of Information.
                      </h3>
                      <p style={{ color: "#212529" }}>
                        Users can send a maximum of 50 packets of information every 30 days, in each Channel of Information, in
                        the free version of the app.
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </main>
      </div>
      <footer className="site-footer">
        <div className="container">
          <div className="site-footer-inner">
            <div className="brand footer-brand">
              <a href="/">
                <img width="64" height="64" src={require("../../assets/img/logoLetters.png")} />
                <title>Slyst</title>
              </a>
            </div>
            <div className="footer-copyright">© 2020 Slyst, all rights reserved</div>
          </div>
        </div>
      </footer>
    </div>
  );
}
